(function ($) {
  Drupal.behaviors.complementaryBenefitFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var self = this;
      var $formatter = $('.js-complementary-benefit-formatter-v1', context);
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);

      $formatter.each(function () {
        var $this = $(this);
        var $carousel = $this.find('.js-complementary-benefit-formatter-carousel');
        var arrowsDiv = $this.find('.carousel-controls');
        var dotsDiv = $this.find('.carousel-dots');
        // Autoplay settings
        var autoplay = $carousel.data('slides-autoplay') || false;
        // Responsive dots / arrows
        var mobileDotsArrowsArgs = self.checkDotsAndArrows($carousel.data('dots-arrows-mobile'));
        var settings = {
          appendArrows: arrowsDiv,
          appendDots: dotsDiv,
          infinite: true,
          autoplay: autoplay,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:
            '<svg role="button" aria-label="previous" class="icon icon--chevron slick-prev slick--custom"><use xlink:href="#chevron"></use></svg>',
          nextArrow:
            '<svg role="button" aria-label="next" class="icon icon--chevron slick-next slick--custom"><use xlink:href="#chevron"></use></svg>',
          dots: mobileDotsArrowsArgs.dots,
          arrows: mobileDotsArrowsArgs.arrows
        };

        // Init this carousel with our settings
        function slick_slider(isMobile) {
          if (isMobile) {
            $carousel.not('.slick-initialized').slick(settings);
          } else {
            if ($carousel.hasClass('slick-initialized')) {
              $carousel.slick('unslick');
            }
          }
        }

        slick_slider(isMobile);

        Unison.on('change', function () {
          var bps = Unison.fetch.all();
          var bp = Unison.fetch.now();
          var isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);

          slick_slider(isMobile);
        });
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery);
